import React, { useMemo, useState, useEffect } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axios_instance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';


const Userlogs = () => {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('Role') === 'ADMIN';
    const [userDetails, setUserDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const isTokenValid = (token) => {
        return token !== null && token !== undefined;
    };
    useEffect(() => {

        // Fetch employee details from the database and update the state
        const fetchData = async () => {
            setIsLoading(true)
            try {
                // Retrieve the token from local storage
                const token = localStorage.getItem('Token'); // Assuming you store the token in local storage

                if (!isTokenValid(token)) {
                    // Redirect to the login page if the token is not valid

                    navigate('/login');
                    return;
                }


                const response = await axiosInstance.get('api/getUserLogs'); // replace with your actual API endpoint
                console.log("response", response.data);
                const username = '';
                const modifiedData = response.data.map(log => ({
                    ...log,
                    username: log.name.split('@')[0] // Extracting username from email

                }));
                console.log("username", username);
                // Sort data by date in descending order
                modifiedData.sort((a, b) => new Date(b.date) - new Date(a.date));
                setIsLoading(false)
                setUserDetails(modifiedData);

                // const sortedData = response.data.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
                // console.log(sortedData)
                // setUserDetails(response.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setIsLoading(false)
                    toast.error("Token Expired .......Please Login again....!", { position: toast.POSITION.TOP_CENTER })
                    sessionStorage.clear();
                    localStorage.clear();
                    localStorage.setItem("islogged", false);
                    navigate("/login")



                } else {
                    setIsLoading(false)
                    console.error('Error fetching employee details:', error);
                    toast.error('Error fetching employee details ..........')
                    sessionStorage.clear();
                    localStorage.clear();
                    localStorage.setItem("islogged", false);
                    navigate("/login")
                }

            }
        };

        fetchData();
    }, []);
    const columns = useMemo(
        () => [
            {
                accessorKey: 'username',
                header: 'User Name ',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                filterVariant: 'text',
            },
            {
                accessorKey: 'name',
                header: 'email ID ',
                filterVariant: 'text',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'message',
                header: 'Log Details',
                filterVariant: 'text',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'date',
                header: 'Date ',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return format(date, 'dd-MM-yyyy'); // Display only the date
                },
                filterVariant: 'daterange',
                filter: ({ value, onChange }) => (
                    <input
                        type="date"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                ),
            },
            // {
            //     accessorKey: 'time',
            //     header: 'Time ',
            //     mantineTableHeadCellProps: {
            //         align: 'center',
            //       },
            //       mantineTableBodyCellProps: {
            //         align: 'center',
            //       },
            //     Cell: ({ cell }) => {
            //         const timeValue = cell.getValue();
            //         // Check if timeValue is a valid time value
            //         if (!timeValue) {
            //             return null; // Return null if timeValue is invalid
            //         }



            //         const date = new Date(cell.getValue());
            //         return format(date, 'hh:mm:ss a'); // Display only the time
            //     },
            //     filterVariant: 'daterange',
            //     filter: ({ value, onChange }) => (
            //         <input
            //             type="time"
            //             value={value}
            //             onChange={(e) => onChange(e.target.value)}
            //         />
            //     ),
            // },



        ],
        [],
    );
    const table = useMantineReactTable({
        enableRowNumbers: false,
        // rowNumberDisplayMode: 'static',
        data: userDetails,
        columns,
        // order: 'desc',
        // orderBy: 'employeeName',
        showBorder: true,
        styles: { th: { fontWeight: 'bold' } },
        components: { Caption: () => null },
    });
    const handleBack = () => {
        navigate("/dashboard")
    }
    return (
        <div style={{ backgroundColor: "#f2edf3", height: '100vh', overflow: 'hidden' }}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-white ">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>


                    <button className="btn btn-primary text-white" onClick={handleBack}>
                        Back To Dashboard
                    </button>

                </div>
            </nav>
            {/* Employee Details Card */}
            <div style={{ padding: '20px' }}>
                <h4 className="card-title text-center" style={{ color: "black" }}>LOG DETAILS</h4>
                {/* <div className="d-flex justify-content-end align-items-center mb-3">
                            </div> */}

                <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    {!isLoading ? (
                        <MantineReactTable table={table} />
                    ) : (
                        <div className="loader">
                            <div className="circular-loader"></div>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default Userlogs