import React, { useMemo, useState, useEffect } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axios_instance';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { CgProfile } from "react-icons/cg";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Flex, Menu, Text, Title, Tooltip, ActionIcon, MultiSelect } from '@mantine/core';
import { IconUserCircle, IconSend, IconTrash } from '@tabler/icons-react';
import { Alert } from 'react-bootstrap';
import '../components/dashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faBars, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { CiMenuBurger } from "react-icons/ci";
import { Card } from 'react-bootstrap';
import BirthDayReminder from './BirthDayReminder';
import Modal from 'react-modal';
import { MdAccountBalance } from "react-icons/md";

const Example = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [reportMode, setReportMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [userName, setUserName] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeUsersList, setActiveUsersList] = useState([]);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [locationOptions, setLocationOptions] = useState([]);

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    employeeId: false,
    employeeName: false,
    emailId: false,
    dateOfBirth: false,
    designation: false,
    department: false,
    location: false,
    workingType: false,
    technicalskills: false,
    dateOfJoining: false,
    exfExperience: false,
    totalExperience: false,
    level: false,
    certifications: false,
    backgroundVerification: false,
    status: false,

  });
  const [countries, setCountries] = useState({});

  const role = localStorage.getItem('Role');
  const isAdmin = role === 'ADMIN';
  const isSuperAdmin = role === 'SUPER_ADMIN';
  const isAdminFinanace = role === 'ADMIN_FINANCE';
  const isAssociateFinance = role === "ASSOCIATE_FINANCE";
  const isAssociateHR = role === "ASSOCIATE_HR";
  const isTokenValid = (token) => {
    return token !== null && token !== undefined;
  };
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  // Effect hook to fetch data when component mounts
  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        // Get the token from wherever you store it, e.g., localStorage
        const token = localStorage.getItem('Token');

        // Make sure token exists
        if (!token) {
          console.error('Token not found');
          return;
        }

        if (!(isAdmin || isAssociateHR || isSuperAdmin)) {
          navigate('/login');
        }

        // Make GET request to the API endpoint with bearer token
        const response = await fetch(process.env.REACT_APP_IP + 'api/count', {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });
        const data = response.json();
        data.then(function (data) {
          console.log(data)
          setCountries(data)
        }).catch(function (error) {
          console.error('Error occurred:', error);
        });

      } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const TimeFilter = ({ value, onChange }) => {
    const handleInputChange = (selectedTime) => {
      onChange(selectedTime);
    };

    return (
      <input
        type="date"
        value={value}
        onChange={(e) => handleInputChange(e.target.value)}
      />
    );
  };

  useEffect(() => {

    // alert("check1")
    // Fetch employee details from the database and update the state
    const fetchData = async () => {

      setColumnVisibility(prevState => {
        const newState = { ...prevState };
        columns.forEach(column => {
          newState[column.accessorKey] = true;
        });
        return newState;
      });


      const userName = localStorage.getItem("Username")
      setUserName(userName)
      setIsLoading(true)
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem('Token'); // Assuming you store the token in local storage

        if (!isTokenValid(token)) {
          // Redirect to the login page if the token is not valid

          navigate('/login');
          return;
        }


        const response = await axiosInstance.get('api/getAllEmployees'); // replace with your actual API endpoint
        console.log("response", response)
        const sortedData = response.data.sort((a, b) => a.employeeName.localeCompare(b.employeeName)) && response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        console.log(sortedData)
        const uniqueLocations = Array.from(new Set(sortedData.map(item => item.location)))
          .map(location => ({ value: location, label: location }));
        console.log("uniqueLocations", uniqueLocations)
        // Set the unique locations to the state
        setLocationOptions(uniqueLocations);
        setEmployeeDetails(sortedData);
        setIsLoading(false)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Token Expired .......Please Login again....!")
          navigate("/login")
          setIsLoading(false)
        } else {
          console.error('Error fetching employee details:', error);
          // toast.error('Error fetching employee details ..........')
          navigate("/login")
          setIsLoading(false)
        }

      }
    };

    fetchData();

  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'employeeId',
        header: 'Employee ID',
        filterVariant: 'text',
        enableHiding: false,
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },


      },
      {
        accessorKey: 'employeeName',
        header: 'Employee Name',
        filterVariant: 'text',

        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'emailId',
        header: 'Email ID',
        filterVariant: 'text',
        enableHiding: false,
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorFn: (row) => {
          const startDate = row && row.dateOfBirth;
          if (startDate && !isNaN(new Date(startDate))) {
            const sDay = new Date(startDate);
            sDay.setHours(0, 0, 0, 0);
            return sDay;
          } else {
            return "NA";
          }
        },
        accessorKey: 'dateOfBirth',
        header: 'Date Of Birth',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!isNaN(new Date(value))) {
            const date = new Date(value);
            return format(date, 'dd-MM-yyyy');
          } else {
            return "NA";
          }
        }
      },

      {
        accessorKey: 'designation',
        header: 'Designation',
        filterVariant: 'text',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'department',
        header: 'Department',
        filterVariant: 'multi-select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'location',
        header: 'Location',
        filterVariant: 'multi-select',
        // mantineFilterMultiSelectProps: {
        //   data: locationOptions,
        //   searchable: true,
        //   clearable: true,
        //   placeholder: 'Select locations',
        // },
        // mantineFilterSelectProps: {
        // data: locationOptions,
        // searchable: true,
        // clearable: true,
        // placeholder: 'Select locations',
        // component: MultiSelect,
        // multiple: true, // Enable multiple select
        // },
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'workingType',
        header: 'Working Type',
        filterVariant: 'multi-select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'technicalskills',
        header: 'Technical Skills',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const technicalSkillsArray = cell.getValue();
          const formattedTechnicalSkills = Array.isArray(technicalSkillsArray)
            ? technicalSkillsArray.join(',')
            : '';

          return formattedTechnicalSkills;
        },
      },
      {
        accessorFn: (row) => {
          const startDate = row && row.dateOfJoining;
          if (startDate && !isNaN(new Date(startDate))) {
            const sDay = new Date(startDate);
            sDay.setHours(0, 0, 0, 0);
            return sDay;
          } else {
            return "NA";
          }
        },
        accessorKey: 'dateOfJoining',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        header: 'Date Of Joining',
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue());
          return format(date, 'dd-MM-yyyy'); // Display only the date
        },
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!isNaN(new Date(value))) {
            const date = new Date(value);
            return format(date, 'dd-MM-yyyy');
          } else {
            return "NA";
          }
        }
      },


      {
        accessorKey: 'exfExperience',
        header: 'ExafluenceExperience',
        filterVariant: 'select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        accessorKey: 'overAllExperience',
        header: 'Total Experience',
        filterVariant: 'select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
      // {
      //   accessorKey: 'totalExperience',
      //   header: 'Total Experience',
      //   mantineTableHeadCellProps: {
      //     align: 'center',
      //   },
      //   mantineTableBodyCellProps: {
      //     align: 'center',
      //   },
      //   filterVariant: 'range',
      //   Cell: ({ cell }) => {
      //     const totalExperience = cell.getValue();
      //     const [years, months] = totalExperience.split('.').map(Number);

      //     return (
      //       <span>
      //         {years} years {months} months
      //       </span>
      //     );
      //   },
      // },
      {
        accessorKey: 'level',
        filterVariant: 'select',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        header: 'Level',
      },
      {
        accessorKey: 'certifications',
        header: 'Certifications',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const certificationsArray = cell.getValue();
          const certificationsSkills = Array.isArray(certificationsArray)
            ? certificationsArray.join(',')
            : '';

          return certificationsSkills;
        },
      },
      // {
      //   accessorKey: 'certifications', 
      //   header: 'Certifications',
      //   filterVariant: 'text',
      // },
      {
        accessorKey: 'backgroundVerification',
        header: 'BackgroundVerification',

        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'boolean',
        Cell: ({ cell }) => (
          <span>{cell.getValue() ? 'Yes' : 'No'}</span>
        ),
      },
      {
        accessorKey: 'status',
        mantineTableHeadCellProps: {
          align: 'center',
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        header: 'Status',
        filterVariant: 'text',
      },

    ],
    [],
  );
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const table = document.getElementById('classTable');
    const rows = table.getElementsByTagName('tr');
    let printContent = '';
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName('td');
      for (let j = 0; j < cells.length; j++) {
        const header = table.getElementsByTagName('th')[j].textContent;
        const key = header.replace(/[\r\n]+/gm, '').trim();
        const value = cells[j].textContent;
        printContent += `<div class="item"><strong>${key}:</strong> <span>${value}</span></div>`;
      }
      printContent += '<hr>';
    }

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Employee_Info</title>
          <style>
            /* Add your styles here */
            body {
              font-family: Arial, sans-serif;
            }
            .item {
              margin-bottom: 5px;
            }
            .item strong {
              font-weight: bold;
            }
            hr {
              border: 0;
              border-top: 1px solid #000;
              margin: 10px 0;
            }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: 'Ashok'
  });
  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    const filteredUsers = employeeDetails.filter(user => {
      return user.country === country && user.status === 'Active';
    });
    setActiveUsersList(filteredUsers);
    setShowAdditionalInfo(filteredUsers.length > 0);
  };

  const [selectedCountry, setSelectedCountry] = useState('');
  // const handleExportRows = (rows, table) => {
  //   const visibleColumns = table.options.state.columnVisibility;
  //   const trueKeys = Object.keys(visibleColumns).filter(key => visibleColumns[key] === true);
  //   const selectedRows = table.getSelectedRowModel().rows;
  //   const rowData = selectedRows.map((row) => row.original);
  //   const filteredData = rowData.map((obj, index) => {
  //     const filteredObj = { Number: index + 1 }; // Add rowNumber property
  //     trueKeys.forEach(key => {
  //       if (obj.hasOwnProperty(key)) {
  //         if (key === 'technicalskills' || key === 'certifications') {
  //           // Combine technical skills or certifications into a single column
  //           if (Array.isArray(obj[key])) {
  //             const skillsOrCerts = obj[key].map(skillOrCert => skillOrCert.includes(' ') ? `"${skillOrCert}"` : skillOrCert);
  //             filteredObj[key] = skillsOrCerts.join(', ');
  //           } else {
  //             filteredObj[key] = obj[key];
  //           }
  //         } else {
  //           filteredObj[key] = Array.isArray(obj[key]) && obj[key].length === 0 ? 'No data' : obj[key];
  //         }
  //       } else {
  //         filteredObj[key] = 'No data';
  //       }
  //     });
  //     return filteredObj;
  //   });
  //   if (filteredData.length > 0) {
  //     const csv = generateCsv(csvConfig)(filteredData);
  //     console.log(csv+"name genrated")
  //     download(csvConfig)(csv);
  //   } else {
  //     alert("Please Select at Least One Row");
  //   }
  // };
  const handleExportRows = (rows, table) => {
    const visibleColumns = table.options.state.columnVisibility;
    const trueKeys = Object.keys(visibleColumns).filter(key => visibleColumns[key] === true);
    const selectedRows = table.getSelectedRowModel().rows;
    const rowData = selectedRows.map((row) => row.original);
    const filteredData = rowData.map((obj, index) => {
      const filteredObj = { Number: index + 1 }; // Add rowNumber property
      trueKeys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
          if (key === 'technicalskills' || key === 'certifications') {
            // Combine technical skills or certifications into a single column
            if (Array.isArray(obj[key])) {
              const skillsOrCerts = obj[key].map(skillOrCert => skillOrCert.includes(' ') ? `"${skillOrCert}"` : skillOrCert);
              filteredObj[key] = skillsOrCerts.join(', ');
            } else {
              filteredObj[key] = obj[key];
            }
          } else if(key === 'dateOfBirth' || key === 'dateOfJoining'){
            filteredObj[key] = formatDate(obj[key]);
            
          }
          else if (key === 'backgroundVerification') {
            // Show "Yes" if background verification is true
            filteredObj[key] = obj[key] === true ? 'Yes' : 'No';
          }
          else {
            filteredObj[key] = Array.isArray(obj[key]) && obj[key].length === 0 ? ' ' : obj[key];
          }
        } else {
          filteredObj[key] = '';
        }
      });
      return filteredObj;
    });

    if (filteredData.length > 0) {
      let filename;
      if (filteredData.length === 1) {
        const employeeName = filteredData[0].employeeName || 'Employee';
        filename = `${employeeName}`;
      } else {
        filename = 'Employee_Details';
      }

      const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
        filename: filename
      });

      const csv = generateCsv(csvConfig)(filteredData);
      download(csvConfig)(csv);
    } else {
      alert("Please Select at Least One Row");
    }
  };


  const openDeleteConfirmModal = (employeeId) => {
    setEmployeeIdToDelete(employeeId);
    setDeleteModalOpen(true);
  };

  const closeDeleteConfirmModal = () => {
    setDeleteModalOpen(false);
    setEmployeeIdToDelete(null);
  };

  const handleConfirmDelete = async (employeeId) => {
    // Perform the deleteEmployee action here
    // You can use employeeIdToDelete to identify the employee to delete
    // ...

    try {
      const response = await axiosInstance.get(`api/deleteEmployee/${employeeId}`);

      // Handle the response, update UI, etc.
      if (response.status === 200) {
        // Update UI (e.g., remove the deleted employee from the list)
        // You might need to refresh the entire list or update the state accordingly
        console.log('Employee deleted successfully');
      } else {
        console.error('Error deleting employee:', response.status);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }

    // Close the modal after the delete action is performed
    closeDeleteConfirmModal();
  };




  // const [pagination, setPagination] = useState({
  //   pageIndex: 2,
  //   pageSize: 100, //customize the default page size
  // });

  const table = useMantineReactTable({
    data: employeeDetails,
    columns,
    enableRowSelection: true,
    selectAllMode: 'all',
    state: { columnVisibility },
    onColumnVisibilityChange: setColumnVisibility,
    enableRowActions: true,
    order: 'desc',
    orderBy: 'employeeName',
    showBorder: true,
    styles: { th: { fontWeight: 'bold' } },
    components: { Caption: () => null },
    mantineFilterTextInputProps: { variant: 'filled' },
    enableFacetedValues: true,
    renderRowActions: (employeeId) => (
      <div className='d-flex'>
        <CgProfile onClick={() => { handleEmployeeClick(employeeId) }} style={{ marginLeft: '10px', marginTop: '12px' }} size={20} />
      </div>
    ),

  });

  const csvData = useMemo(() => {
    return selectedRows.map((row) => {
      return columns.map((column) => row[column.accessorKey]);
    });
  }, [selectedRows]);

  const handleLogout = () => {
    // Clear session data
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem("islogged", false);
    window.location.href = "/";

    // Redirect to the login page (replace '/login' with your actual login route)
    // navigate('/login');

  }

  const handleDownload = () => {
    // Implement the logic to download the selected data as a report
    // Once the download is complete, you may reset the state and exit the report mode
    setReportMode(false);
    setSelectedRows([]);
  };
  const handleCreateEmployeeClick = () => {
    // Use navigate function to go to the '/registration_page' route handleCreateFinance
    navigate('/registration_page');
  };
  const handleCreateFinance = () => {
    // Use navigate function to go to the '/registration_page' route handleCreateUser
    navigate('/finance');
  };
  const handleUsersList = () => {
    // Use navigate function to go to the '/registration_page' route handleCreateUser
    navigate('/users_list');
  };


  const handleCreateUser = () => {
    // Use navigate function to go to the '/registration_page' route handleCreateUser
    navigate('/SignUp');
  };
  const handleFinancePage = () => {
    navigate('/financedetails')
  }

  const handleEmployeeClick = (employeeId) => {
    console.log(employeeId.row.original.employeeId)
    sessionStorage.setItem('EmployeeId', employeeId.row.original.employeeId);
    // Use navigate function to go to the '/registration_page' route
    navigate('/profile');

  };
  const formatExperience = (experience) => {
    if (experience.includes('0 years 1 months')) {
      return experience.replace('0 years 1 months', '1 month').trim();
    } else if (experience.includes('1 months')) {
      return experience.replace('1 months', '1 month');
    } else if (experience.includes('0 years 0 months')) {
      return '0-1 month';
    } else if (experience.includes('0 months')) {
      return experience.replace('0 months', '');
    } else {
      return experience;
    }
  };


  const handleCreatelogsClick = () => {

    // Use navigate function to go to the '/registration_page' route
    navigate('/userlogs');

  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  function setTableData() {

    const selectedRows = table.getSelectedRowModel().rows;
    const selectedCOlumns = table.options.state.columnVisibility;
    const rowData = selectedRows.map((row) => row.original);
    setSelectedColumns(selectedCOlumns);
    setRowData(rowData);

  }

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleReset = () => {
    navigate("/reset")
  }

  return (
    <div style={{ backgroundColor: "#f2edf3", overflow: 'hidden' }}>
      {/* Image and text */}

      <nav className="navbar navbar-expand-lg bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="exalogo.png" alt="Logo" className="logo" />
          </a>
          <h2>
            <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
            <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
            <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
          </h2>
          <BirthDayReminder componentName="DashBoard" />
        </div>
        {(isSuperAdmin || isAdmin) &&
          <div>

            <div className="container-fluid">
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <button className="btn btn-info dropdown-toggle" onClick={handleMenuToggle} aria-expanded={menuOpen}>
                      Menu
                    </button>
                    {/* <CiMenuBurger title='Menu' onClick={handleMenuToggle} aria-expanded={menuOpen} color='white'/> */}
                    <ul className={`dropdown-menu ${menuOpen ? 'show' : ''}`}>



                      <li><button className="dropdown-item" onClick={handleCreateEmployeeClick}>Create Employee</button></li>
                      <li><button className="dropdown-item" onClick={handleFinancePage}>Add Finance Info</button></li>
                      <li><button className="dropdown-item" onClick={handleCreateUser}>Add user</button></li>
                      <li><button className="dropdown-item" onClick={handleCreateFinance}>Finance Dashboard</button></li>
                      <li><button className="dropdown-item" onClick={handleUsersList}>Users List</button></li>

                      <li><button className="dropdown-item" onClick={handleCreatelogsClick}>Logs</button></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

          </div>

        }
        {(isAdminFinanace || isAssociateFinance) &&
          <div>

            <div className="container-fluid">
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <button className="btn btn-info dropdown-toggle" onClick={handleMenuToggle} aria-expanded={menuOpen}>
                      Menu
                    </button>
                    {/* <CiMenuBurger title='Menu' onClick={handleMenuToggle} aria-expanded={menuOpen} color='white'/> */}
                    <ul className={`dropdown-menu ${menuOpen ? 'show' : ''}`}>
                      <li><button className="dropdown-item" onClick={handleCreateFinance}>Finance DashBoard</button></li>
                      <li><button className="dropdown-item" onClick={handleFinancePage}>Finance Page</button></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        }

        {isAssociateHR &&
          <div>

            <div className="container-fluid">
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <button className="btn btn-light dropdown-toggle" onClick={handleMenuToggle} aria-expanded={menuOpen}>
                      Menu
                    </button>
                    <ul className={`dropdown-menu ${menuOpen ? 'show' : ''}`}>
                      <li><button className="dropdown-item" onClick={handleCreateEmployeeClick}>Create Employee</button></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

          </div>

        }
        <div className='d-flex justify-content-end mt-2 align-items-center ' style={{ marginRight: "1.5rem" }} onClick={handleToggleDropdown}>
          <FontAwesomeIcon icon={faCircleUser} size="3x" color="white" />
          {isDropdownOpen && (
            <div className='d-profile'>
              {/* Your dropdown items go here */}
              <h5 className="m-1">{userName}</h5>
              <span className="reset-password" onClick={handleReset}>Reset password</span>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          )}
        </div>

      </nav>

      {/* Employee Details Card */}


      <div style={{ maxHeight: 'auto', overflow: 'auto', padding: '30px', }} >
        <div className="col-12" >
          <h5 className=" mb-2 ">Employee Details</h5>
          {isLoading && (
            <div className="loader">
              <div className="circular-loader"></div>
            </div>
          )}
          {!isLoading ? (
            <div className="card" >
              <div className="card-body">
                <div className='row d-flex justify-content-start'>
                  
                    <div className='col-8'>
                    {!(isAssociateHR || isAssociateFinance) &&
                      <div className="d-flex justify-content-start mb-3">
                        <div>
                          <label htmlFor="country" style={{ marginRight: '70px' }}>
                            Country <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select form-select-lg mb-3"
                            aria-label=".form-select-lg example"
                            id="country"
                            name="country"
                            onChange={handleCountryChange}
                            value={selectedCountry}
                            required
                          >
                            <option value="">Select Location</option>
                            {Object.entries(countries).map(([countryName, countValue]) => (
                              <option value={countryName}>
                                {countryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {showAdditionalInfo && (
                          <div className='mt-4'>
                            <a href={`/ActiveUserList?country=${selectedCountry}`} style={{ textDecoration: "none", marginLeft: '30px' }}>
                              <button className="btn btn-info btn-sm">Active Users In {selectedCountry} : {activeUsersList.length}</button>
                            </a>
                          </div>
                        )}

                      </div>
                       }
                    </div>
                 
                  <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          {rowData?.length > 0 ? <h5 class="modal-title" id="exampleModalLabel">Download Employee Data Now!</h5> : <h5 class="modal-title" id="exampleModalLabel">Select Employee</h5>}

                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body " style={{ maxHeight: '300px' }}>
                          {rowData?.length > 0 ? (
                            <table id="classTable" className="table table-bordered">
                              <thead>
                                <tr>
                                  {Object.keys(selectedColumns)
                                    .filter(column => !column.startsWith('mrt'))
                                    .map(column => (
                                      selectedColumns[column] ? (
                                        <th key={column} className="cell">
                                          {column}
                                        </th>
                                      ) : null
                                    ))}
                                </tr>
                              </thead>
                              <tbody>
                                {rowData.map((user, index) => (
                                  <tr key={index}>
                                    {Object.keys(selectedColumns)
                                      .filter(column => !column.startsWith('mrt'))
                                      .map(column => (
                                        selectedColumns[column] ? (
                                          <td key={column} className="cell">
                                            {user[column] ? (
                                              column === 'exfExperience' ? formatExperience(user[column]) :
                                                Array.isArray(user[column]) && user[column].length === 0 ? 'No data' :
                                                  (column === 'dateOfJoining' || column === 'dateOfBirth' ? formatDate(user[column]) : user[column].toString())
                                            ) : 'No data'}
                                          </td>
                                        ) : null
                                      ))}
                                  </tr>
                                ))}
                              </tbody>




                            </table>
                          ) : (
                            <p>Select atleast one Employee to Generate Report</p>
                          )}





                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                          <Button
                            // disabled={
                            //   !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            // }
                            //only export selected rows
                            onClick={() => handleExportRows(rowData, table)}
                            startIcon={<FileDownloadIcon />}
                            disabled={!Object.keys(rowData).length > 0}
                            data-dismiss="modal"
                          >
                            Download CSV
                          </Button>
                          <Button

                            onClick={() => handlePrint()}
                            disabled={!Object.keys(rowData).length > 0}
                            data-dismiss="modal"
                          >
                            Print
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary mt-2"
                        data-toggle="modal"
                        data-target="#exampleModal1"
                        onClick={() => {
                          setTableData();
                        }}
                      >
                        Generate report
                      </button>
                    </div>
                  </div>

                </div>
                {employeeDetails ? (
                  <div className='row'>
                    <div className='col-12' style={{ paddingBottom: '10px' }}>
                      <div> {/* Adjust the maxHeight as needed */}
                        <MantineReactTable table={table} style={{ marginBottom: '60px' }} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="loader">
                    <div className="circular-loader"></div>
                  </div>
                )}



                {/* The rest of your table code */}
              </div>
            </div>
          ) : (
            <div className="loader">
              <div className="circular-loader"></div>
            </div>
          )}

        </div>
      </div>
    </div>

  )
  // return <MantineReactTable table={table} />;
};

export default Example;

