import React, { useState, useEffect } from 'react';
import List from "./List";
import { Modal, Button, Carousel } from 'react-bootstrap';
import { IoNotificationsCircleSharp } from "react-icons/io5";
import '../components/Remainder.css'
import { IoMdSend } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaMobileRetro } from "react-icons/fa6";
import Hello from '../assets/Hello.jpg'
import { IoIosCall } from "react-icons/io";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Row, Col, Nav } from 'react-bootstrap';
import BirthDayGif from '../assets/celebrate.gif'
export default function BirthDayReminder(props) {
  const componentName = props.componentName;
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [weekcount, setWeekCount] = useState(0);
  const [tomorrowData, setTomorrowData] = useState(null);
  const [tomorrowCount, setTomorrowCount] = useState(0);
  const [thisweekdata, setThisWeekData] = useState(null);
  const [employeeCount,setEmployeeCount]=useState(null);
  const clearAll = () => setCount(0);
  const showAll = () => setCount(data?.tomorrow_birthday?.length + data?.week_birthday?.length);

  const btnClass = count ? "btn-danger" : "btn-success";
  const btnTxt = count ? "Clear All" : "Show All";
  const btnAction = count ? clearAll : showAll;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = React.useState('Tomorrow');
  const [hikeEmployees, setHikeEmployees] = useState([]);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const   hikeEmployee=async(token)=> {
    const response = await fetch(process.env.REACT_APP_IP +'api/hikeRemainder', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    const json = await response.json();
    console.log("Hey Response",json)
    setEmployeeCount(json.length)
    setHikeEmployees(json);

  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem('Token');

        if (componentName === "DashBoard") {
          const response = await fetch(process.env.REACT_APP_IP +'api/remainder', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
          }
          const json = await response.json();
          let weekcount1 = json?.week_birthday.length;
          setThisWeekData(json?.week_birthday)
          setTomorrowData(json?.tomorrow_birthday);
          setCount(weekcount1);
          setData(json);
          setWeekCount(json.week_birthday.length);
          setTomorrowCount(json.tomorrow_birthday.length);
        }
        else {
          hikeEmployee(token);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }


    fetchData();
  }, []);


  const renderTabContent = () => {
    switch (activeTab) {
      case 'Tomorrow':
        return (
          <Carousel
            prevIcon={<span className="carousel-control-prev-icon" />}
            nextIcon={<span className="carousel-control-next-icon" />}
          >
            {tomorrowData?.map((item, index) => (
              <Carousel.Item key={index}>
                <Col>
                  <Card style={{ paddingBottom: '15px', borderWidth: '2px', borderColor: 'black', backgroundColor: '#f0f0f0' }}>
                    <Card.Body>
                      <div className="d-flex justify-content-around mb-4">
                        {/* <img src={Hello} className="employee-image rounded-circle" alt="" style={{ width: '60px', height: '50px', backgroundSize: 'cover' }} /> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className='mb-4'>
                            <FaUserCircle size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            <Card.Text >{item.employeeName}</Card.Text>
                          </div>
                          <div>
                            <MdOutlineEmail size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            <Card.Text style={{ marginLeft: '40px' }}>{item.emailId}</Card.Text>
                          </div>

                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className='mb-4'>
                            <FaUserCircle size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            <Card.Text >{item.dateOfBirth}</Card.Text>
                          </div>
                          <div>
                            <MdOutlineEmail size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            {item.phoneNumber ? <Card.Text ><IoIosCall size={20} style={{ marginRight: '5px' }} />{item.phoneNumber}</Card.Text> : null}
                          </div>

                        </div>
                      </div>


                    </Card.Body>
                  </Card>
                </Col>
              </Carousel.Item>
            ))}
            {/* Add more Carousel.Item components for additional employees */}
          </Carousel>
        );
      case 'This Week':
        return (
          <Carousel
            prevIcon={<span className="carousel-control-prev-icon" />}
            nextIcon={<span className="carousel-control-next-icon" />}
          >
 {thisweekdata?.map((item, index) => (
              <Carousel.Item key={index}>
                <Col>
                  <Card style={{ paddingBottom: '15px', borderWidth: '2px', borderColor: 'black', backgroundColor: '#f0f0f0' }}>
                    <Card.Body>
                      <div className="d-flex justify-content-around mb-4">
                        {/* <img src={Hello} className="employee-image rounded-circle" alt="" style={{ width: '60px', height: '50px', backgroundSize: 'cover' }} /> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className='mb-4'>
                            <FaUserCircle size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            <Card.Text >{item.employeeName}</Card.Text>
                          </div>
                          <div>
                            <MdOutlineEmail size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            <Card.Text style={{ marginLeft: '40px' }}>{item.emailId}</Card.Text>
                          </div>

                        </div>

                        {/* <div className="d-flex flex-column justify-content-end" style={{ marginTop: '46px' }}>
                          {item.phoneNumber ? <Card.Text ><IoIosCall size={20} style={{ marginRight: '5px' }} />{item.phoneNumber}</Card.Text> : null}
                        </div> */}
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className='mb-4'>
                            <FaUserCircle size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            <Card.Text >{item.dateOfBirth}</Card.Text>
                          </div>
                          <div>
                            <MdOutlineEmail size={20} style={{ position: 'absolute', left: 35, marginTop: '3px' }} />
                            {item.phoneNumber ? <Card.Text ><IoIosCall size={20} style={{ marginRight: '5px' }} />{item.phoneNumber}</Card.Text> : null}
                          </div>

                        </div>
                      </div>


                    </Card.Body>
                  </Card>
                </Col>
              </Carousel.Item>
            ))}


          </Carousel>
        );
      default:
        return <div>
          <p>Say Hi</p>
        </div>;
    }
  };
  // Empty array means this effect will only run once, on mount
  return (
    <>
      {/* <IoNotificationsCircleSharp size={20}  onClick={handleShow}>
      Open Modal
    </IoNotificationsCircleSharp> */}
      <span>
        <button type="button" class="btn btn-info" data-toggle="modal" data-target="#exampleModal">
          <i class="fa-solid fa-bell"></i>
        </button>
        {componentName== "Finance" ? ( // Render badge only if employeeCount is greater than 0
        <span className="badge badge-light">{employeeCount}</span>
      ) :  <span className="badge badge-light">{count}</span>}
      </span>
      <div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      {(weekcount>0 || employeeCount>0)   &&(
  <div style={{ position: 'absolute', top: 99, right: 0 }}>
            {componentName === "DashBoard" ? (
    <Card style={{ width: '30rem' }}>
      <Card.Header>
        <p>Birthdays Reminder</p>
          <Nav variant="tabs" className="nav-fill">
            <Nav.Item className="flex-grow-1 text-center">
              <Nav.Link
                onClick={() => handleTabClick('Tomorrow')}
                active={activeTab === 'Tomorrow'}
              >
                Tomorrow: <span style={{ fontWeight: 'bold' }}>{tomorrowCount}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-grow-1 text-center">
              <Nav.Link
                onClick={() => handleTabClick('This Week')}
                active={activeTab === 'This Week'}
              >
                This Week: <span style={{ fontWeight: 'bold' }}>{weekcount}</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>    
      </Card.Header>
      <Card.Body>
    
        <Row>{renderTabContent()}</Row>
      </Card.Body>
    </Card> ): (
       <Card style={{ width: '26rem' }}>
       <Card.Header>
       Upcoming Employee Hike Details
       </Card.Header>
       <Card.Body style={{overflow:'auto',maxHeight:'20rem'}}>

       {hikeEmployees.map((employee, index) => (

  <> <div key={index} className='employee-info' >
  <p className='employee-info__name'>Name: {employee.employeeName}</p>
  <p className='employee-info__id'>ID: {employee.employeeId}</p>
</div></>
))}
       </Card.Body>
     </Card>
    )}
  </div>
)}

      </div>
      {/* <Modal show={show} onHide={handleClose} onClick={() => { setCount(0) }}>
      {Boolean(count) &&
            <List key={data.id} data={data} />}


      </Modal> */}

    </>
  );
}
