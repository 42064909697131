import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.css';

import ClickJack from './components/ClickJack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>
);

reportWebVitals();
// Won't render the application if the WebApp is under a Clickjacking attack
if (window.self === window.top) {
  root.render(<App />);
} else {
  root.render(<ClickJack />);
}