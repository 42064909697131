// import React from 'react'
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './axios_instance';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Flex, Menu, Text, Title, Tooltip, ActionIcon, MultiSelect } from '@mantine/core';
import { faCircleUser, faBars, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
const UsersList = () => {

    const [usersList, setUsersList] = useState([]);
    const navigate = useNavigate(); 
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isTokenValid = (token) => {
        return token !== null && token !== undefined;
    };

    useEffect(() => {
        const userName = localStorage.getItem("Username")
      setUserName(userName)

        const fetchData = async () => {
            try {
                const token = localStorage.getItem('Token');

                if (!isTokenValid(token)) {
                    navigate('/dashboard');
                    return;
                }
                setIsLoading(true);

                axiosInstance
                    .get("api/getAllUsers")
                    .then((response) => {
                        console.log("response", response.data);
                        setUsersList(response.data);
                    })
                    .catch((error) => {
                        console.error("Error fetching history:", error);
                        setIsLoading(false);
                    });
            } catch (error) {
                console.error("Error occurred", error)
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleBack = () => {
        navigate("/dashboard");
    };
    // const usersList = [
    //     { username: 'JohnDoe', role: 'Admin', designation: 'Manager' },
    //     { username: 'JaneSmith', role: 'User', designation: 'Developer' },
    //     // Add more user objects as needed
    //   ];

    // const toggleUserEnable = async (username) => {
    //     console.log(`Toggle enable/disable for user: ${username}`);
    //     // const token = localStorage.getItem('Token');
    //     const response = await axios.get(
    //         process.env.REACT_APP_IP + "api/userAction/"+username,
    //         {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 Authorization: `Bearer ${localStorage.getItem('Token')}`,
    //             },
    //         }
    //     );
    //     console.log(response);
    //   };



      const toggleUserEnable = async (username) => {
        console.log(`Toggle enable/disable for user: ${username}`);
        try {
          const token = localStorage.getItem('Token');
    
          if (!isTokenValid(token)) {
            navigate('/dashboard');
            return;
          }
    
          const response = await axios.get(
            process.env.REACT_APP_IP + "api/userAction/" + username,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          
          if (response.status === 200) {
            window.location.reload();
            setUsersList((prevUsersList) => 
              prevUsersList.map(user =>
                user.username === username ? { ...user, enable: !user.enable } : user
              )
            );
            console.log("Role is updated successfully");
          } else {
            console.error("Error fetching history:", response);
          }
        } catch (error) {
          console.error("Error occurred", error);
        }
      };

      const handleLogout = () => {
        // Clear session data
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("islogged", false);
        window.location.href = "/";
    
        // Redirect to the login page (replace '/login' with your actual login route)
        // navigate('/login');
    
      }

    const deleteUser = async (username) =>{
        console.log(`delete user: ${username}`);
        const response = await axios.get(
            process.env.REACT_APP_IP + "api/deleteUser/"+username,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('Token')}`,
                },
            }
        );

        if (response.status === 200) {
            window.location.reload();
            console.log("user deleted sucessfully");
        }
        else{
            console.log("something went wrong");
        }
    }
    const handleReset = () => {
        navigate("/reset")
      }

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };

    const renderUsersList = () => {


        const tableStyle = {
            width: '100%',
            borderCollapse: 'collapse',
            // marginBottom: '20px',
        };

        const thTdStyle = {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'center',
        };

        const thStyle = {
            ...thTdStyle,
            backgroundColor: '#f2f2f2',
            fontWeight: 'bold',
            textAlign:'center'
        };

        const noDataStyle = {
            ...thTdStyle,
            textAlign: 'center',
            padding: '10px',
        };




        return(

<div className="row mt-2">
<h5>List Of  Users</h5>
    <div className="col-12">
      <table className="table table-bordered" style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Username</th>
            <th style={thStyle}>Role</th>
            <th style={thStyle}>Designation</th>
            <th style={thStyle}></th> 
          </tr>
        </thead>
        <tbody>
              {usersList.map((user, index) => (
                <tr key={index}>
                  <td style={thTdStyle}>{user.email}</td>
                  <td style={thTdStyle}>{user.roles}</td>
                  <td style={thTdStyle}>{user.designation}</td>
                  <td style={thTdStyle}>
                    <button
                      className={`btn btn-sm mr-2 ${user.enable ? 'btn-success' : 'btn-secondary'}`}
                      style={{ opacity: user.enable ? 1 : 0.5 }}
                      onClick={() => toggleUserEnable(user.email)}
                    >
                      {user.enable ? 'Disable' : 'Enable'}
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteUser(user.email)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
      </table>
    </div>
  </div>


        )

    }

  return (
    <div>
              <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>
                    <button className="btn btn-primary text-white" onClick={handleBack}>
                        Back To Dashboard
                    </button>
                </div>

                <div className='d-flex justify-content-end mt-2 align-items-center ' style={{ marginRight: "1.5rem" }} onClick={handleToggleDropdown}>
          <FontAwesomeIcon icon={faCircleUser} size="3x" color="blue" />
          {isDropdownOpen && (
            <div className='d-profile'>
              {/* Your dropdown items go here */}
              <h5 className="m-1">{userName}</h5>
              <span className="reset-password" onClick={handleReset}>Reset password</span>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          )}
        </div>

            </nav>
            <div className="row mt-5">
                {/* <div className="d-flex justify-content-end">
                    <button
                        className="btn btn-primary mt-2"
                        onClick={downloadCSV}
                    >
                        Download
                    </button>
                    <a
                        ref={downloadLinkRef}
                        style={{ display: 'none' }}
                        href="/"
                        download="financial_history.csv"
                    >
                        Download Link
                    </a>

                    <button
                        className="btn btn-primary mt-2 ml-2"
                        onClick={printPDF}
                    >
                        Print
                    </button>
                </div> */}
                <div className="col-md-12">
    <div className="card" style={{ borderRadius: "5px" }}>
        <div className="card-body" style={{ backgroundColor: "rgb(10, 56, 115)", padding: "10px" }}>
            <h3 className="card-title text-center" style={{ color: "white", margin: "0" }}>Users List</h3>
        </div>
        <div>
             {renderUsersList()}
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
  )
}

export default UsersList
