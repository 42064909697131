import React from 'react';
import './clickjack.css'
const ClickJack = () => {
  return (
    <div className="warningContainer">
      <h1 className='Message'>Unauthorized Access</h1>

      <a href={window.location.href} target="_top" className="safeLink">
        Click here to open the application securely
      </a>
    </div>
  )
}

export default ClickJack;
