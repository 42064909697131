import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        console.log("e", username);
        try {
            // Email domain validation
            const validEmailDomain = /@exafluence\.com$/;
            if (!validEmailDomain.test(username)) {
                setErrorMessage('Invalid email domain ');
                setUsername('');

                return;
            }

            console.log("username", username);
            const response = await axios.post(
                process.env.REACT_APP_IP + `api/auth/forgotPassword?email=${username}`,
            );
            console.log("response.status", response.status)
            if (response.status === 200) {
                console.log('Email sent successful:', response.data);
                toast.success("Email Sent SuccessFul", { position: toast.POSITION.TOP_CENTER })
                navigator("/login")

            }
            else {
                console.log("status", response.status);
                setErrorMessage('Invalid Username ');
                toast.error("Invalid Username ", { position: toast.POSITION.TOP_CENTER })
            }
        } catch (error) {
            if (error.response && error.response.status === 429) {
                toast.error('Too many reset attempts. Please try again after 24 hours.', { position: toast.POSITION.TOP_CENTER });
            } else if (error.response) {
                console.error('Error response:', error.response);
                toast.error('Error occurred while processing your request', { position: toast.POSITION.TOP_CENTER });
            } else {
                console.error('Error:', error);
                toast.error('Failed to send request', { position: toast.POSITION.TOP_CENTER });
            }
        }
    };

    const handleBack = () => {
        navigate("/login")
    }

    return (
        <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>
                    <IoMdArrowRoundBack onClick={handleBack} size={25} style={{marginLeft:'20'}}/>
                    
                
                </div>
            </nav>
            <div className="container mt-3">
                <div className="card " style={{ boxShadow: "0px 0px 5px #000" }}>
                    <div className="card-body" style={{ textAlign: 'left' }}>
                        <div className="text-center pt-4  pb-4 bg-primary text-white">
                            <h3>Forgot Password</h3>
                        </div>
                        <div className="card-body py-5 px-md-5">
                            <div className='row justify-content-center '>
                                <form >
                                    <div className='row mb-4 justify-content-center'>
                                        <div className='col-md-8'><label id='email'>Email :</label>

                                            <input class="form-control"
                                                type="email"
                                                placeholder="Enter email"
                                                id='email'
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                required
                                            />
                                            {errorMessage && (
                                                <div style={{ color: "red", }}>
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </div>

                                    </div>


                                </form>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary mt-3 mb-4" onClick={handleLogin}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ForgotPassword