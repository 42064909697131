import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
    const navigate = useNavigate();
    const initialUserDetails = {
        email: '',
        userName: '',
        password: '',
        designation: '',
        roles: [''],
        enable: true
    };

    const [userDetails, setUserdetails] = useState(initialUserDetails);

    const [errors, setErrors] = useState({
        email: '',
        userName: '',
        password: '',
        designation: '',
        roles: '',
    });

    const handleInputChange = (e) => {
        const { name, value, options } = e.target;
        if (name === 'roles') {
            const selectedRoles = Array.from(options)
                .filter(option => option.selected)
                .map(option => option.value);

            setUserdetails((userDetails) => ({
                ...userDetails,
                [name]: selectedRoles,
            }));
        } else {
            setUserdetails((userDetails) => ({
                ...userDetails,
                [name]: value,
            }));
        }

        validateInput(name, value);
    };

    const validateInput = (name, value) => {
        switch (name) {
            case 'email':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: !value ? 'Enter Email' : !/@exafluence\.com$/.test(value) ? 'Invalid email format' : '',
                }));
                break;

            case 'userName':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    userName: !value ? 'Enter User Name' : '',
                }));
                break;

                case 'password':
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*(.)\1)(?!.*(\d)\2{2,})(?!.*0123)(?!.*1234)(?!.*2345)(?!.*3456)(?!.*4567)(?!.*5678)(?!.*6789).{10,}$/;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: !value
                            ? 'Enter Password'
                            : !passwordRegex.test(value)
                                ? 'Password must be at least 10 characters long, contain at least one special character, one uppercase letter, one lowercase letter, one digit, and must not have consecutive repeating characters or a sequence of 3 or more consecutive digits.'
                                : '',
                    }));
                    break;
                
                

            case 'designation':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    designation: !value ? 'Enter Designation' : '',
                }));
                break;

            case 'roles':
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    roles: value ? '' : 'Select a Role',
                }));
                break;

            default:
                break;
        }
    };

    const handleReset = () => {
        setUserdetails(initialUserDetails);
    };

    const handleBack = () => {
        navigate("/dashboard");
    };

    const role = localStorage.getItem('Role');
    const isSuperAdmin = role === 'SUPER_ADMIN';

    useEffect(() => {
        const token = localStorage.getItem('Token');
        if (!token) {
            navigate("/login");
        }

        if (!isSuperAdmin) {
            navigate('/login');
        }
    }, [navigate, isSuperAdmin]);

    const handleSignup = async () => {
        try {
            for (const key in userDetails) {
                validateInput(key, userDetails[key]);
            }

            // Check if there are any validation errors
            if (Object.values(errors).some(error => error !== '')) {
                return;
            }

            console.log("userDetails", userDetails);
            const response = await axios.post(`${process.env.REACT_APP_IP}api/auth/createuser`, userDetails, { 
                headers: { Authorization: "Bearer " + localStorage.getItem("Token") } 
            });

            console.log(response.data + " response");

            // On successful submission
            toast.success("Form submitted successfully", { position: toast.POSITION.TOP_CENTER });
            setUserdetails(initialUserDetails); // Clear the form
            navigate("/dashboard");
        } catch (error) {
            console.error('Error during signup:', error);

            // Check if the error response indicates the user already exists
            if (error.response && error.response.status === 409 && error.response.data.includes("Already Employee Exists")) {
                toast.error("User already exists", { position: toast.POSITION.TOP_CENTER });
                setUserdetails(initialUserDetails);
            } else {
                // Handle other errors
                toast.error("An error occurred during signup", { position: toast.POSITION.TOP_CENTER });
                setUserdetails(initialUserDetails);
            }
        }
    };

    return (
        <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
            <ToastContainer />
            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>
                    <button className="btn btn-primary text-white" onClick={handleBack}>
                        Back To Dashboard
                    </button>
                </div>
            </nav>
            <div className="container mt-3 justify" style={{ padding: '20px' }}>
                <div className="card" style={{ boxShadow: "0px 0px 5px #000", width: "100%", borderRadius: "2%" }}>
                    <div className="card-body" style={{ textAlign: 'left' }}>
                        <div className="text-center pt-4 pb-4 bg-primary text-white">
                            <h3>Add User</h3>
                        </div>
                        <div className="card-body py-5 px-md-5 justify-content-center">
                            <div className='row text-left'>
                                <div className='row mb-4 justify-content-center '>
                                    <div className='col-md-8'>
                                        <label id='email' htmlFor="email">Email Id :</label>
                                        <input className="form-control"
                                            type="text"
                                            placeholder="Enter Email Id"
                                            id="email"
                                            name='email'
                                            value={userDetails.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}
                                    </div>
                                </div>
                                <div className='row mb-4 justify-content-center '>
                                    <div className='col-md-8'>
                                        <label id='userName' htmlFor="userName">User Name :</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter user name"
                                            id="userName"
                                            name='userName'
                                            value={userDetails.userName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.userName && <div style={{ color: "red" }}>{errors.userName}</div>}
                                    </div>
                                </div>
                                <div className='row mb-4 justify-content-center '>
                                    <div className='col-md-8'>
                                        <label id='password' htmlFor="password">Password :</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Password"
                                            id="password"
                                            name='password'
                                            value={userDetails.password}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.password && <div style={{ color: "red" }}>{errors.password}</div>}
                                    </div>
                                </div>
                                <div className='row mb-4 justify-content-center '>
                                    <div className='col-md-8'>
                                        <label id='designation' htmlFor="designation">Designation :</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Designation"
                                            id="designation"
                                            name='designation'
                                            value={userDetails.designation}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {errors.designation && <div style={{ color: "red" }}>{errors.designation}</div>}
                                    </div>
                                </div>
                                <div className='row mb-4 justify-content-center '>
                                    <div className='col-md-8'>
                                        <label id='roles' htmlFor="roles">Role :</label>
                                        <select
                                            className="form-control"
                                            id="roles"
                                            name='roles'
                                            value={userDetails.roles}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select a Role</option>
                                            <option value="ADMIN">admin</option>
                                            <option value="SUPER_ADMIN">super_admin</option>
                                            <option value="ADMIN_FINANCE">admin_finance</option>
                                            <option value="ASSOCIATE_HR">associate_hr</option>
                                            <option value="ASSOCIATE_FINANCE">associate_finance</option>
                                        </select>
                                        {errors.roles && <div style={{ color: "red" }}>{errors.roles}</div>}
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary" onClick={() => handleSignup()} >Create User</button><span style={{ margin: '0 10px' }}></span>
                                    <button type='reset' className="btn btn-secondary" onClick={handleReset} >Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SignUp;
