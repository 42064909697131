import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPasword = () => {

  const [password, setPassword] = useState('');
  const [resetPassword, setResetPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const handleResetPasswordChange = (e) => {
    setResetPassword(e.target.value);
    setResetPasswordError('');
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const urlParams = new URLSearchParams(document.location.search);
  const tokenValue = urlParams.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*(.)\1)(?!.*(\d)\2{2,})(?!.*0123)(?!.*1234)(?!.*2345)(?!.*3456)(?!.*4567)(?!.*5678)(?!.*6789).{10,}$/;

    // Validation messages
    const validationMessage = 'Password must be at least 10 characters long, contain at least one special character, one uppercase letter, one lowercase letter, one digit, and must not have consecutive repeating characters or a sequence of 3 or more consecutive digits.';

    if (!password) {
      setPasswordError('Please enter a password');
    } else if (!passwordRegex.test(password)) {
      setPasswordError(validationMessage);
    }

    if (!resetPassword) {
      setResetPasswordError('Please enter a confirm password');
    } else if (!passwordRegex.test(resetPassword)) {
      setResetPasswordError(validationMessage);
    }

    if (password !== resetPassword) {
      setPasswordError('Passwords do not match.');
      setResetPasswordError('Passwords do not match.');
      return;
    }

    if (!passwordRegex.test(password)) {
      setPasswordError(validationMessage);
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_IP + `api/auth/resetPassword?token=${tokenValue}&newPassword=${password}`,
      );

      if (response.status === 200) {
        toast.success("Password Reset successfully ", { position: toast.POSITION.TOP_CENTER })
        navigate("/login")
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      // Handle error, show error message
    }
  };

  const handleBack = () => {
    navigate("/login");
  };

  const handleReset = () => {
    setPassword('');
    setResetPassword('');
    setPasswordError('');
    setResetPasswordError('');
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
      <nav className="navbar navbar-expand-lg navbar-dark bg-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="exalogo.png" alt="Logo" className="logo" />
          </a>
          <h2>
            <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
            <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
            <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
          </h2>
          <button className="btn btn-primary text-white" onClick={handleBack}>
            Back To Dashboard
          </button>
        </div>
      </nav>
      <div className="container mt-3">
        <div className="card " style={{ boxShadow: "0px 0px 5px #000" }}>
          <div className="card-body" style={{ textAlign: 'left' }}>
            <div className="text-center pt-4  pb-4 bg-primary text-white">
              <h3>Reset Password</h3>
            </div>
            <div className="card-body py-5 px-md-5">
              <div className='row justify-content-center '>
                <form onSubmit={handleSubmit}>

                  <div className='row mb-4 justify-content-center'>
                    <div className='col-md-8'>
                      <label id='password'>New password :</label>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type={showPassword ? 'text' : 'password'}
                          placeholder='Enter New password'
                          id='password'
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                        <span onClick={handleTogglePasswordVisibility} style={{ cursor: 'pointer', padding: '10px' }}>
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </span>
                      </div>
                      {passwordError && <div className="text-danger">{passwordError}</div>}
                    </div>
                  </div>

                  <div className='row mb-4 justify-content-center'>
                    <div className='col-md-8'>
                      <label id='confirmpassword'>Confirm password :</label>
                      <div className='input-group'>
                        <input
                          className="form-control"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Enter Confirm password"
                          id='confirmpassword'
                          value={resetPassword}
                          onChange={handleResetPasswordChange}
                          required
                        />
                        <span onClick={handleTogglePasswordVisibility} style={{ cursor: 'pointer', padding: '10px' }}>
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </span>
                      </div>
                      {resetPasswordError && <div className="text-danger">{resetPasswordError}</div>}
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">Reset password</button>
                    <span style={{ margin: '0 10px' }}></span>
                    <button type='button' onClick={handleReset} className="btn btn-secondary">Clear</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasword;
