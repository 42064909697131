import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Token } from '@mui/icons-material';
import axiosInstance from './axios_instance';

const Reset = () => {

  const [password, setPassword] = useState('');
  const [resetPassword, setResetPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');

  const navigate = useNavigate();

  const role = localStorage.getItem('Role');
  const isAdmin = role === 'ADMIN';
  const isSuperAdmin = role === 'SUPER_ADMIN';
  const isAdminFinanace = role === 'ADMIN_FINANCE';
  const isAssociateFinance = role === "ASSOCIATE_FINANCE";
  const isAssociateHR = role === "ASSOCIATE_HR";

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleResetPasswordChange = (e) => {
    setResetPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const authToken = localStorage.getItem('Token');
    // Perform authentication logic with the authToken
    // Redirect to login if token is not present or invalid
    if (!authToken) {
      navigate('/login'); // You might want to redirect to the login page
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*(.)\1)(?!.*(\d)\2{2,})(?!.*0123)(?!.*1234)(?!.*2345)(?!.*3456)(?!.*4567)(?!.*5678)(?!.*6789).{10,}$/;

    // Validation messages
    const validationMessage = 'Password must be at least 10 characters long, contain at least one special character, one uppercase letter, one lowercase letter, one digit, and must not have consecutive repeating characters or a sequence of 3 or more consecutive digits.';

    // if (!password || !passwordRegex.test(password)) {
    //     setPasswordError(validationMessage);
    //     return;
    // }
    if (!password) {
      setPasswordError('Please enter a password');
    } else if (!passwordRegex.test(password)) {
      setPasswordError(validationMessage);
    }
    
    if (!resetPassword) {
      setResetPasswordError('Please enter a confirm password');
    } else if (!passwordRegex.test(resetPassword)) {
      setResetPasswordError(validationMessage);
    }

    // if (!resetPassword  || !passwordRegex.test(password)) {
    //     setResetPasswordError('Please confirm your password.');
    //     return;
    // }

    if (password !== resetPassword) {
        setPasswordError('Passwords do not match.');
        setResetPasswordError('Passwords do not match.');
        return;
    }

    if (!passwordRegex.test(password)) {
        setPasswordError(validationMessage);
        return;
    }

    // Check if passwords match
    const email = localStorage.getItem('Email');
    if (password === resetPassword) {
        try {
            const response = await axiosInstance.post(
                `api/auth/updatePassword?newPassword=${password}&email=${email}`
            );

            if (response.status === 200) {
                toast.success('Password Reset successfully', { position: toast.POSITION.TOP_CENTER });
                navigate('/dashboard'); // Assuming navigate is imported correctly for routing
            }
        } catch (error) {
            // Handle error if API request fails
            console.error('Error updating password:', error);
            // Optionally show error message to user
            toast.error('Failed to update password. Please try again.', { position: toast.POSITION.TOP_CENTER });
        }
    } else {
        setPasswordError('Passwords do not match');
        setResetPasswordError('Passwords do not match');
    }
};

  const handleBack = () => {
    navigate("/dashboard")
  };

  const handleReset = () => {
    setPassword('');
    setResetPassword('');
    setPasswordError('');
    setResetPasswordError('');
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
      <nav className="navbar navbar-expand-lg navbar-dark bg-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="exalogo.png" alt="Logo" className="logo" />
          </a>
          <h2>
            <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
            <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
            <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
          </h2>
          <button className="btn btn-primary text-white" onClick={handleBack}>
            Back To Dashboard
          </button>
        </div>
      </nav>
      <div className="container mt-3">
      <ToastContainer />
        <div className="card " style={{ boxShadow: "0px 0px 5px #000" }}>
          <div className="card-body" style={{ textAlign: 'left' }}>
            <div className="text-center pt-4  pb-4 bg-primary text-white">
              <h3>Reset Password</h3>
            </div>
            <div className="card-body py-5 px-md-5">
              <div className='row justify-content-center '>
                <form onSubmit={handleSubmit} >
                  <div className='row mb-4 justify-content-center'>
                    <div className='col-md-8'>
                      <label htmlFor='password'>New password :</label>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type={showPassword ? 'text' : 'password'}
                          placeholder='Enter New password'
                          id='password'
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                        <span onClick={handleTogglePasswordVisibility} style={{ cursor: 'pointer', padding: '10px' }}>
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </span>
                      </div>
                      {passwordError && <p className="text-danger">{passwordError}</p>}
                    </div>
                  </div>
                  <div className='row mb-4 justify-content-center'>
                    <div className='col-md-8'>
                      <label htmlFor='confirmpassword'>Confirm password :</label>
                      <div className='input-group'>
                        <input
                          className='form-control'
                          type={showPassword ? 'text' : 'password'}
                          placeholder='Enter Confirm password'
                          id='confirmpassword'
                          value={resetPassword}
                          onChange={handleResetPasswordChange}
                          required
                        />
                        <span onClick={handleTogglePasswordVisibility} style={{ cursor: 'pointer', padding: '10px' }}>
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </span>
                      </div>
                      {resetPasswordError && <p className="text-danger">{resetPasswordError}</p>}
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Reset password</button>
                    <span style={{ margin: '0 10px' }}></span>
                    <button type='button' onClick={handleReset} className="btn btn-secondary">Clear</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reset;
