import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './axios_instance';
import { toast } from 'react-toastify';

const ActiveUsers = () => {
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [sortedData, setSortedData] = useState([]); // State to hold sorted data
  const [selectedLocation, setSelectedLocation] = useState('');
  const [activeUsersList, setActiveUsersList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const country = searchParams.get('country');
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/dashboard");
  }
  const handleLocationbChange = (event) => {
    const location = event.target.value;
    setSelectedLocation(location);
    const filteredEmployees = sortedData.filter((employee) => {
      return employee.country === country && employee.location === location && employee.status === "Active";
    });
    setEmployeeDetails(filteredEmployees);

  };
  const filterEmployeesByCountry = (country) => {
    const filteredEmployees = sortedData.filter((employee) => {
      return employee.country === country && employee.status === "Active";
    });
    setEmployeeDetails(filteredEmployees);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const token = localStorage.getItem('Token');
        if (country === 'India') {
          setLocations(['tirupati', 'bangalore', 'chennai', 'Gulbarga', 'Hyderabad']);
        } else if (country === 'USA') {
          setLocations(['New York', 'Los Angeles', 'Chicago', 'Houston', 'San Francisco']);
        } else if (country === 'canada') {
          setLocations(['Tofino',]);
        } else {
          setLocations([]);
        }
        // Perform authentication logic with the authToken
        // Redirect to login if token is not present or invalid
        if (!token) {
          navigate('/login'); // You might want to redirect to the login page
          return;
        }
        const response = await axiosInstance.get('api/getAllEmployees');
        console.log("response", response);
        const sortedData = response.data.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        setSortedData(sortedData); // Set sorted data in state
        setIsLoading(false)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsLoading(false)
          toast.error("Token Expired. Please Login again.");
          navigate("/login");
        } else {
          setIsLoading(false)
          console.error('Error fetching employee details:', error);
          toast.error('Error fetching employee details.');
          navigate("/login");
        }
      }
    };

    fetchData(); // Fetch data on component mount
  }, []); // Run effect only once on mount to fetch data

  useEffect(() => {
    // Filter employees when country changes
    if (country && sortedData.length > 0) {
      filterEmployeesByCountry(country);
    }
  }, [country, sortedData]); // Run effect when country or sortedData changes

  return (
    <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
      <nav className="navbar navbar-expand-lg navbar-dark bg-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="exalogo.png" alt="Logo" className="logo" />
          </a>
          <h2>
            <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
            <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
            <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
          </h2>
          <button className="btn btn-primary text-white" onClick={handleBack}>
            Back To Dashboard
          </button>
        </div>
      </nav>
      {/* table */}
      <h5 className="card-title text-center mt-4 mb-1">List of Active Employees in {country}</h5>
      <div className="mt-4" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
        <div>
          <label htmlFor="country" style={{ display: 'block', marginBottom: '5px', position: 'absolute', left: 90, top: 140, }}>
            <span style={{ color: "red" }}>*</span> Location
          </label>
          <select
            className="form-select form-select-lg mb-3 small-width-select"
            aria-label=".form-select-lg example"
            id="country"
            name="country"
            onChange={handleLocationbChange}
            value={selectedLocation}
            required

          >

            <option value="">Select Location</option>
            {locations.map((location, index) => (
              <option key={index} value={location}>{location}</option>
            ))}
          </select>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Eid</th>
              <th style={{ width: '10%' }}>Ename</th>
              <th style={{ width: '20%' }}>Email</th>
              <th style={{ width: '10%' }}>Country</th>
              <th style={{ width: '15%' }}>Location</th>
            </tr>
          </thead>
          <tbody>
          {isLoading ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <div className="loader">
                    <div className="circular-loader"></div>
                  </div>
                </td>
              </tr>
            ) : (
              employeeDetails.map((employee) => (
                <tr key={employee.employeeId}>
                  <td>{employee.employeeId}</td>
                  <td>{employee.employeeName}</td>
                  <td>{employee.emailId}</td>
                  <td>{employee.country}</td>
                  <td>{employee.location}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>


    </div>
  );
}

export default ActiveUsers;
