import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_IP ,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('Token')}`,
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        // Attach the token to the request headers
        const token = localStorage.getItem('Token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Handle token expiration or other errors
        console.log("error.response",error.response.status)
        if (error.response && error.response.status === 401) {
            // Token expired or unauthorized, redirect to login
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle token expiration or other errors
        if (error.response && error.response.status === 401) {
            // Token expired or unauthorized, redirect to login
            const navigate = useNavigate();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
